<template>
  <div>
    <div class="mx-4">
      <PageTitle title="Настройки" />

      <v-tabs
        background-color="transparent"
        class="base-tabs custom-tabs-arrows"
        color="primary"
        show-arrows
      >
        <v-tab v-for="(tab, i) in tabs" :key="i" :to="tab.to" class="tab">
          {{ tab.text }}
        </v-tab>
      </v-tabs>

      <v-container fluid class="mt-4 pa-0">
        <router-view />
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageTitle from '@/components/ui/Title'

export default {
  name: 'Settings',

  components: {
    PageTitle
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    this.dataLoaded = true
  },

  data: () => ({
    dataLoaded: false,
  }),

  computed: {
    tabs() {
      return [
        { text: 'Типы занятий', to: `/settings/academic-works` },
        { text: 'Доступ к данным слушателя', to: `/settings/cycle-access` },
        { text: 'Заведующий учебной частью', to: `/settings/head-teacher` },
        { text: 'Управление периодом выгрузки', to: `/settings/uploading-parameters` }
      ]
    }
  }
}
</script>